import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "Familjen Grotesk",
  },
  palette: {
    primary: {
      main: "#E26D39",
    },
    secondary: {
      main: "#2F4C2A",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
    },
  },
});
