import { Box, Typography } from '@mui/material';
import Dashboard from 'pages/Dashboard';

function App() {
  // return <Dashboard />;

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >
      <Typography>We are Currently undermaintenance</Typography>
    </Box>
  );
}

export default App;
